
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import AtomButton, { ButtonVariant, ButtonSize } from '@/components/atoms/AtomButton.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomActionSelector from '@/components/atoms/AtomActionSelector.vue';
import MoleculeDateSection from '@/components/molecules/date/MoleculeDateSection.vue';
import MoleculeModal, { ModalSize } from '@/components/molecules/modal/MoleculeModal.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismTimetableCard from '@/components/molecules/cards/MoleculeTimetableCard.vue';
import TimetablesRolesEnum from '@/enums/timetable/TimetablesRolesEnum';
import { sortMomentAsc } from '@/services/utilities/sort';
import { TimetableModel } from '@/models/timetable/TimetableModel';
import { TimetableType } from '@/api/timetable/timetableApi';

export const TEMPLATE_NAME = 'TemplateTimetableWeb';

export default Vue.extend({
  name: TEMPLATE_NAME,
  components: {
    AtomButton,
    AtomSvgIcon,
    AtomText,
    AtomActionSelector,
    MoleculeDateSection,
    MoleculeModal,
    OrganismPageSubHeader,
    OrganismTimetableCard,
  },
  data() {
    return {
      TimetableType,
      ButtonVariant,
      ButtonSize,
      ModalSize,
      showDepartures: true,
      currentTime: '',
      siteId: this.$route.params.id,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      unsubscribe() {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      interval: setInterval(() => {}, 0),
      days: [] as moment.Moment[],
      selectedDay: moment().startOf('day'),
      TimetablesRolesEnum,
    };
  },
  props: {
    timetableType: {
      type: String as PropType<TimetableType>,
      required: true,
    },
  },
  methods: {
    ...mapActions('rideIntent', [
      'hydrateWizardFromTimeTable',
    ]),
    tabToggle(show: boolean): void {
      this.showDepartures = show;
    },
    setTime(): void {
      this.currentTime = moment().format('HH:mm');
    },
    updateData(): void {
      const { siteId, timetableType } = this;
      this.fetchData({ siteId, type: timetableType });
    },
    changeSite(siteId: string) {
      this.siteId = siteId;
      this.$router.push({ params: { id: siteId } });
      // this.$router.go(0); // reloads the page with new parameter
      this.updateData();
      this.$bvModal.hide('timetableweb-modal');
    },
    ...mapActions('timetable', ['fetchToken', 'fetchData']),
    tripsPerDay(date: moment.Moment): TimetableModel[] {
      return this.getDepartures
        .filter((departure) => moment(departure.date).isSame(date, 'day'))
        .sort(({ date: a }, { date: b }) => sortMomentAsc(a, b));
    },
    arrivalsPerDay(date: moment.Moment): TimetableModel[] {
      return this.getArrivals
        .filter((arrival) => moment(arrival.date).isSame(date, 'day'))
        .sort(({ date: a }, { date: b }) => sortMomentAsc(a, b));
    },
    async handleCardClick(trip: TimetableModel) {
      localStorage.setItem('locale', this.$i18n.locale);
      window.location.href = trip.createUrl;
    },
  },
  computed: {
    siteName(): string {
      return this.getSiteName;
    },
    currentSiteId(): number {
      return Number(this.siteId);
    },
    arrivals(): moment.Moment[] {
      return this.getArrivals.reduce((datesList, currentArrivals) => {
        if (!datesList.some((date) => moment(currentArrivals.date).isSame(date, 'day'))) {
          datesList.push(moment(currentArrivals.date));
        }
        return datesList;
      }, [] as moment.Moment[])
        .sort(sortMomentAsc);
    },
    departures(): moment.Moment[] {
      return this.getDepartures.reduce((datesList, currentDeparture) => {
        if (!datesList.some((date) => (moment(currentDeparture.date).isSame(date, 'day')))) {
          datesList.push(moment(currentDeparture.date));
        }
        return datesList;
      }, [] as moment.Moment[])
        .sort(sortMomentAsc);
    },
    ...mapGetters('timetable', [
      'getSiteName',
      'getSiteAddress',
      'getArrivals',
      'getDepartures',
      'getSites',
    ]),
    selectedDate: {
      get(): moment.Moment {
        return this.selectedDay;
      },
      set(date: moment.Moment) {
        this.selectedDay = moment(date).startOf('day');
      },
    },
  },
  beforeMount() {
    const { siteId } = this;
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'timetable/setToken') {
        // this.fetchData({ siteId, type: TimetableType.FIND_BY_SITE_ID });
        this.updateData();
      }
    });
    this.fetchToken({ siteId, type: TimetableType.FIND_BY_SITE_ID });
    this.setTime();
  },
  mounted() {
    const updateClockAndData = () => {
      this.setTime();
      this.updateData();
    };
    this.interval = setInterval(updateClockAndData, 60000);
  },
  beforeDestroy() {
    this.unsubscribe();
    clearInterval(this.interval);
  },
});
