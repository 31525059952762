
import Vue from 'vue';
import i18n from '@/i18n';
import TemplateTimetableWeb from '@/components/templates/timetable/TemplateTimetableWeb.vue';
import { TimetableType } from '@/api/timetable/timetableApi';

export default Vue.extend({
  name: 'TimetablePortal',
  components: { TemplateTimetableWeb },
  metaInfo: {
    title: ` - ${i18n.t('tabs.portal')}`,
  },
  computed: {
    timetableType() {
      return this.$router.currentRoute.meta?.timetableType as TimetableType;
    },
  },
});
